import { takeLatest, all } from 'redux-saga/effects';

import api from '../Services/Api';

/* ------------- Types ------------- */

import { StartupTypes } from '../Redux/StartupRedux';
import { AuthTypes } from '../Redux/AuthRedux';
import { CitiesTypes } from '../Redux/CitiesRedux';
import { LeadsTypes } from '../Redux/LeadsRedux';
import { TasksTypes } from '../Redux/TasksRedux';
import { HoldingTypes } from '../Redux/HoldingRedux';
import { LeaguesTypes } from '../Redux/LeaguesRedux';
import { OrganisationsLeagueTypes } from '../Redux/OrganisationsLeagueRedux';
import { OrganisationTypes } from '../Redux/OrganisationRedux';
import { OrganisationMembershipTypes } from '../Redux/OrganisationMembershipRedux';
import { UsersTypes } from '../Redux/UsersRedux';
import { EmailTypes } from '../Redux/EmailRedux';
import { CareNotebooksTypes } from '../Redux/CareNotebooksRedux';
import { DealsTypes } from '../Redux/DealsRedux';
import { CoursesTypes } from '../Redux/CoursesRedux';
import { ArticlesTypes } from '../Redux/ArticlesRedux';
import { ClubsTypes } from '../Redux/ClubsRedux';
import { WebNotificationSubscriptionsTypes } from '../Redux/WebNotificationSubscriptionsRedux';
import { TransmissionsTypes } from '../Redux/TransmissionsRedux';
import { AlgoliaTypes } from '../Redux/AlgoliaRedux';

/* ------------- Sagas ------------- */

import startup from './StartupSagas';
import {
  setAuthToken,
  removeAuthToken,
  signIn,
  resetPassword,
  changePassword,
  changePin,
  signUpInvitation,
  signUpConfirmation,
  signUpOrganisation,
  logout,
  resendConfirmationEmail,
  createHelperSubscription,
  updateHelperSubscription,
  deleteHelperSubscription,
  getHelperNotifications,
  updateHelperNotification,
  deleteHelperNotification,
  updateHelper,
} from './AuthenticationSagas';
import getCities from './GetCitiesSagas';
import {
  createQualifiedLead,
  createSharedLead,
  createNonQualifiedLead,
  getLead,
  createLeadPost,
  getOrganisationLeads,
  getHoldingLeads,
  getLeagueLeads,
  getLeagueLead,
  createLeagueLead,
  updateLeagueLead,
  transferLeagueLead,
  getFamilyLeads,
  getFamilyLead,
  updateOrganisationLead,
  updateFamilyLead,
  updateOrganisationLeadAndPostComment,
  getOrganisationAcceptedLeadsRange,
  getOrganisationLeadsByType,
  getOrganisationLeadsByDay,
  getOrganisationLeadsByWeek,
  getOrganisationLeadsByMonth,
  getOrganisationTotalHelpers,
  getOrganisationTopHelpers,
  getOrganisationHelpers,
} from './LeadsSagas';
import {
  getOrganisations,
  getLeagueOrganisations,
  addHoldingLeague,
  removeHoldingLeague,
} from './GetOrganisationsLeagueSagas';
import {
  getOrganisation,
  getFamily,
  getOrganisationByIdentifier,
  getOrganisationCircles,
  getOrganisationServiceTypes,
  getOrganisationService,
  getOrganisationServices,
  addOrganisationService,
  updateOrganisationService,
  removeOrganisationService,
  updateOrganisation,
  updateFamilyHelped,
  // getOrganisationTopHelpers,
} from './OrganisationSagas';
import {
  getHolding,
  updateHolding,
  getHoldings,
  addHoldingOrganisation,
  createOrganisationHolding,
  createHolding,
  removeHoldingOrganisation,
  getHoldingServices,
  addHoldingService,
  updateHoldingService,
  removeHoldingService,
  getHoldingService,
  getAllPathologies,
  getAllExpertises,
  getAllEstablishmentTypes,
  getLevels,
  addHoldingRubric,
  updateHoldingRubric,
  removeHoldingRubric,
} from './HoldingSagas';
import {
  getLeague,
  createLeagueCandidate,
  getLeagues,
  getLeagueTasks,
  getLeagueSettings,
  updateLeagueSettings,
  updateHoldingPremiumStatus,
  getLeagueHelpers,
  getLeagueHelper,
  createLeagueHelper,
  updateLeagueHelper,
  transferLeagueHelper,
  updateLeagueHelperInfos,
} from './LeaguesSagas';
import {
  getOrganisationMembership,
  updateOrganisationMembership,
  destroyOrganisationMembership,
  createOrganisationMembership,
  resendInvitation,
} from './OrganisationMembershipSagas';
import {
  getUser,
  getUserFromEmail,
  createUser,
  updateUser,
  getUsers,
  getUserOrganisations,
  createHelped,
} from './UsersSagas';
import {
  getOrganisationTransmissions,
  getLeagueTransmissions,
  createOrganisationTransmission,
  createLeagueTransmission,
  updateOrganisationTransmission,
  updateLeagueTransmission,
  destroyOrganisationTransmission,
  destroyLeagueTransmission,
  createOrganisationTransmissionPost,
  createLeagueTransmissionPost,
  updateOrganisationTransmissionPost,
  updateLeagueTransmissionPost,
  destroyOrganisationTransmissionPost,
  destroyLeagueTransmissionPost,
} from './TransmissionsSagas';
import {
  createTask,
  updateTask,
  skipOccurence,
  detachTaskOccurrence,
  createTaskPost,
  destroyTask,
  getTwigs,
  getTwig,
  getTask,
  registerHelperOnTask,
  unregisterHelperOnTask,
  validateHelperOnTask,
} from './TasksSagas';
import { checkEmailToken, sendEmail } from './EmailSagas';
import {
  getCareNotebookPosts,
  getCareNotebookPost,
  createCareNotebookPost,
  updateCareNotebookPost,
  deleteCareNotebookPost,
  createCareNotebookPostComment,
  deleteCareNotebookPostComment,
} from './CareNotebooksSagas';
import {
  createWebNotificationSubscription,
  checkWebNotificationSubscription,
  destroyWebNotificationSubscription,
} from './WebNotificationSubscriptionsSagas';
import {
  getDeals,
  getDeal,
  createDeal,
  updateDeal,
  destroyDeal,
  createDealPost,
  destroyDealPost,
} from './DealsSagas';
import {
  getLeagueCourses,
  getLeagueCourse,
  createLeagueCourse,
  updateLeagueCourse,
  destroyLeagueCourse,
  createLeagueCourseUnit,
  updateLeagueCourseUnit,
  destroyLeagueCourseUnit,
  getLeagueCourseChapter,
  createLeagueCourseChapter,
  updateLeagueCourseChapter,
  destroyLeagueCourseChapter,
  getHoldingCourses,
  getHoldingCourse,
  createHoldingCourse,
  updateHoldingCourse,
  destroyHoldingCourse,
  createHoldingCourseUnit,
  updateHoldingCourseUnit,
  destroyHoldingCourseUnit,
  getHoldingCourseChapter,
  createHoldingCourseChapter,
  updateHoldingCourseChapter,
  destroyHoldingCourseChapter,
  createUserChapterLink,
  getFamilyCourses,
  getCourse,
  getCourseChapter,
} from './CoursesSagas';
import {
  getHoldingArticles,
  getHoldingArticle,
  createHoldingArticle,
  updateHoldingArticle,
  destroyHoldingArticle,
  getLeagueArticles,
  getLeagueArticle,
  createLeagueArticle,
  updateLeagueArticle,
  destroyLeagueArticle,
  getArticle,
} from './ArticlesSagas';
import {
  getOrganisationClubs,
  getOrganisationClub,
  createClub,
  updateClub,
  destroyClub,
  createClubMemberships,
  destroyClubMembership,
  getClubPosts,
  createClubPost,
} from './ClubsSagas';
import { getAlgoliaOrganisations, getAlgoliaCourses } from './AlgoliaSagas';

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield all([
    takeLatest(StartupTypes.STARTUP, startup, api),

    takeLatest(AuthTypes.SET_AUTH_TOKEN_REQUEST, setAuthToken, api),
    takeLatest(AuthTypes.REMOVE_AUTH_TOKEN, removeAuthToken, api),
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn, api),
    takeLatest(AuthTypes.RESET_PASSWORD_REQUEST, resetPassword, api),
    takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword, api),
    takeLatest(AuthTypes.CHANGE_PIN_REQUEST, changePin, api),
    takeLatest(AuthTypes.SIGN_UP_INVITATION_REQUEST, signUpInvitation, api),
    takeLatest(AuthTypes.SIGN_UP_CONFIRMATION_REQUEST, signUpConfirmation, api),
    takeLatest(AuthTypes.SIGN_UP_ORGANISATION_REQUEST, signUpOrganisation, api),
    takeLatest(AuthTypes.RESET_ON_LOGOUT, logout, api),
    takeLatest(AuthTypes.RESEND_CONFIRMATION_EMAIL_REQUEST, resendConfirmationEmail, api),
    takeLatest(AuthTypes.CREATE_HELPER_SUBSCRIPTION_REQUEST, createHelperSubscription, api),
    takeLatest(AuthTypes.UPDATE_HELPER_SUBSCRIPTION_REQUEST, updateHelperSubscription, api),
    takeLatest(AuthTypes.DELETE_HELPER_SUBSCRIPTION_REQUEST, deleteHelperSubscription, api),

    takeLatest(AuthTypes.GET_HELPER_NOTIFICATIONS_REQUEST, getHelperNotifications, api),
    takeLatest(AuthTypes.UPDATE_HELPER_NOTIFICATION_REQUEST, updateHelperNotification, api),
    takeLatest(AuthTypes.DELETE_HELPER_NOTIFICATION_REQUEST, deleteHelperNotification, api),

    takeLatest(AuthTypes.UPDATE_HELPER_REQUEST, updateHelper, api),

    takeLatest(CitiesTypes.GET_CITIES_REQUEST, getCities, api),

    takeLatest(LeadsTypes.CREATE_NON_QUALIFIED_LEAD_REQUEST, createNonQualifiedLead, api),
    takeLatest(LeadsTypes.GET_LEAD_REQUEST, getLead, api),
    takeLatest(LeadsTypes.CREATE_QUALIFIED_LEAD_REQUEST, createQualifiedLead, api),
    takeLatest(LeadsTypes.CREATE_SHARED_LEAD_REQUEST, createSharedLead, api),
    takeLatest(LeadsTypes.CREATE_LEAD_POST_REQUEST, createLeadPost, api),

    takeLatest(LeadsTypes.GET_ORGANISATION_LEADS_REQUEST, getOrganisationLeads, api),
    takeLatest(LeadsTypes.GET_HOLDING_LEADS_REQUEST, getHoldingLeads, api),

    takeLatest(LeadsTypes.GET_LEAGUE_LEADS_REQUEST, getLeagueLeads, api),
    takeLatest(LeadsTypes.GET_LEAGUE_LEAD_REQUEST, getLeagueLead, api),
    takeLatest(LeadsTypes.CREATE_LEAGUE_LEAD_REQUEST, createLeagueLead, api),
    takeLatest(LeadsTypes.UPDATE_LEAGUE_LEAD_REQUEST, updateLeagueLead, api),
    takeLatest(LeadsTypes.TRANSFER_LEAGUE_LEAD_REQUEST, transferLeagueLead, api),
    takeLatest(LeadsTypes.GET_FAMILY_LEADS_REQUEST, getFamilyLeads, api),
    takeLatest(LeadsTypes.GET_FAMILY_LEAD_REQUEST, getFamilyLead, api),
    takeLatest(LeadsTypes.UPDATE_ORGANISATION_LEAD_REQUEST, updateOrganisationLead, api),
    takeLatest(
      LeadsTypes.UPDATE_ORGANISATION_LEAD_AND_POST_COMMENT_REQUEST,
      updateOrganisationLeadAndPostComment,
      api,
    ),
    takeLatest(LeadsTypes.UPDATE_FAMILY_LEAD_REQUEST, updateFamilyLead, api),
    //
    // OrganisationsLeagueTypes
    //
    takeLatest(
      OrganisationsLeagueTypes.GET_LEAGUE_ORGANISATIONS_REQUEST,
      getLeagueOrganisations,
      api,
    ),
    takeLatest(OrganisationsLeagueTypes.ADD_HOLDING_LEAGUE_REQUEST, addHoldingLeague, api),
    takeLatest(
      LeadsTypes.GET_ORGANISATION_ACCEPTED_LEADS_RANGE_REQUEST,
      getOrganisationAcceptedLeadsRange,
      api,
    ),
    takeLatest(LeadsTypes.GET_ORGANISATION_LEADS_BY_TYPE_REQUEST, getOrganisationLeadsByType, api),
    takeLatest(LeadsTypes.GET_ORGANISATION_LEADS_BY_DAY_REQUEST, getOrganisationLeadsByDay, api),
    takeLatest(LeadsTypes.GET_ORGANISATION_LEADS_BY_WEEK_REQUEST, getOrganisationLeadsByWeek, api),
    takeLatest(
      LeadsTypes.GET_ORGANISATION_LEADS_BY_MONTH_REQUEST,
      getOrganisationLeadsByMonth,
      api,
    ),
    takeLatest(OrganisationsLeagueTypes.REMOVE_HOLDING_LEAGUE_REQUEST, removeHoldingLeague, api),
    takeLatest(OrganisationsLeagueTypes.GET_ORGANISATIONS_REQUEST, getOrganisations, api),

    //
    // organisation
    //
    takeLatest(OrganisationTypes.GET_ORGANISATION_REQUEST, getOrganisation, api),
    takeLatest(OrganisationTypes.UPDATE_ORGANISATION_REQUEST, updateOrganisation, api),
    takeLatest(OrganisationTypes.GET_FAMILY_REQUEST, getFamily, api),
    takeLatest(
      OrganisationTypes.GET_ORGANISATION_BY_IDENTIFIER_REQUEST,
      getOrganisationByIdentifier,
      api,
    ),
    takeLatest(OrganisationTypes.GET_ORGANISATION_CIRCLES_REQUEST, getOrganisationCircles, api),
    takeLatest(
      OrganisationTypes.GET_ORGANISATION_SERVICE_TYPES_REQUEST,
      getOrganisationServiceTypes,
      api,
    ),
    takeLatest(OrganisationTypes.GET_ORGANISATION_SERVICE_REQUEST, getOrganisationService, api),
    takeLatest(OrganisationTypes.GET_ORGANISATION_SERVICES_REQUEST, getOrganisationServices, api),
    takeLatest(OrganisationTypes.ADD_ORGANISATION_SERVICE_REQUEST, addOrganisationService, api),
    takeLatest(
      OrganisationTypes.UPDATE_ORGANISATION_SERVICE_REQUEST,
      updateOrganisationService,
      api,
    ),
    takeLatest(
      OrganisationTypes.REMOVE_ORGANISATION_SERVICE_REQUEST,
      removeOrganisationService,
      api,
    ),
    takeLatest(OrganisationTypes.UPDATE_FAMILY_HELPED_REQUEST, updateFamilyHelped, api),

    takeLatest(LeadsTypes.GET_ORGANISATION_TOP_HELPERS_REQUEST, getOrganisationTopHelpers, api),
    takeLatest(LeadsTypes.GET_ORGANISATION_TOTAL_HELPERS_REQUEST, getOrganisationTotalHelpers, api),
    takeLatest(LeadsTypes.GET_ORGANISATION_HELPERS_REQUEST, getOrganisationHelpers, api),
    takeLatest(TasksTypes.GET_TWIGS_REQUEST, getTwigs, api),
    takeLatest(TasksTypes.GET_TWIG_REQUEST, getTwig, api),
    takeLatest(TasksTypes.GET_TASK_REQUEST, getTask, api),
    takeLatest(TasksTypes.CREATE_TASK_POST_REQUEST, createTaskPost, api),

    //
    // Holding
    //
    takeLatest(HoldingTypes.GET_HOLDING_REQUEST, getHolding, api),
    takeLatest(HoldingTypes.GET_HOLDINGS_REQUEST, getHoldings, api),
    takeLatest(HoldingTypes.CREATE_HOLDING_REQUEST, createHolding, api),
    takeLatest(HoldingTypes.UPDATE_HOLDING_REQUEST, updateHolding, api),
    takeLatest(HoldingTypes.ADD_HOLDING_ORGANISATION_REQUEST, addHoldingOrganisation, api),
    takeLatest(HoldingTypes.REMOVE_HOLDING_ORGANISATION_REQUEST, removeHoldingOrganisation, api),
    takeLatest(HoldingTypes.CREATE_ORGANISATION_HOLDING_REQUEST, createOrganisationHolding, api),
    takeLatest(HoldingTypes.GET_HOLDING_SERVICE_REQUEST, getHoldingService, api),
    takeLatest(HoldingTypes.GET_HOLDING_SERVICES_REQUEST, getHoldingServices, api),
    takeLatest(HoldingTypes.ADD_HOLDING_SERVICE_REQUEST, addHoldingService, api),
    takeLatest(HoldingTypes.UPDATE_HOLDING_SERVICE_REQUEST, updateHoldingService, api),
    takeLatest(HoldingTypes.REMOVE_HOLDING_SERVICE_REQUEST, removeHoldingService, api),
    takeLatest(HoldingTypes.GET_ALL_PATHOLOGIES_REQUEST, getAllPathologies, api),
    takeLatest(HoldingTypes.GET_ALL_EXPERTISES_REQUEST, getAllExpertises, api),
    takeLatest(HoldingTypes.GET_LEVELS_REQUEST, getLevels, api),
    takeLatest(HoldingTypes.GET_ALL_ESTABLISHMENT_TYPES_REQUEST, getAllEstablishmentTypes, api),
    takeLatest(HoldingTypes.ADD_HOLDING_RUBRIC_REQUEST, addHoldingRubric, api),
    takeLatest(HoldingTypes.UPDATE_HOLDING_RUBRIC_REQUEST, updateHoldingRubric, api),
    takeLatest(HoldingTypes.REMOVE_HOLDING_RUBRIC_REQUEST, removeHoldingRubric, api),

    //
    // League
    //
    takeLatest(LeaguesTypes.GET_LEAGUE_REQUEST, getLeague, api),
    takeLatest(LeaguesTypes.GET_LEAGUES_REQUEST, getLeagues, api),
    takeLatest(LeaguesTypes.CREATE_CANDIDATE_LEAGUE_REQUEST, createLeagueCandidate, api),
    takeLatest(LeaguesTypes.GET_LEAGUE_TASKS_REQUEST, getLeagueTasks, api),
    takeLatest(LeaguesTypes.GET_LEAGUE_SETTINGS_REQUEST, getLeagueSettings, api),
    takeLatest(LeaguesTypes.UPDATE_LEAGUE_SETTINGS_REQUEST, updateLeagueSettings, api),
    takeLatest(LeaguesTypes.UPDATE_HOLDING_PREMIUM_STATUS_REQUEST, updateHoldingPremiumStatus, api),
    takeLatest(LeaguesTypes.GET_LEAGUE_HELPERS_REQUEST, getLeagueHelpers, api),
    takeLatest(LeaguesTypes.GET_LEAGUE_HELPER_REQUEST, getLeagueHelper, api),
    takeLatest(LeaguesTypes.CREATE_LEAGUE_HELPER_REQUEST, createLeagueHelper, api),
    takeLatest(LeaguesTypes.UPDATE_LEAGUE_HELPER_REQUEST, updateLeagueHelper, api),
    takeLatest(LeaguesTypes.TRANSFER_LEAGUE_HELPER_REQUEST, transferLeagueHelper, api),
    takeLatest(LeaguesTypes.UPDATE_LEAGUE_HELPER_INFOS_REQUEST, updateLeagueHelperInfos, api),

    takeLatest(
      OrganisationMembershipTypes.GET_ORGANISATION_MEMBERSHIP_REQUEST,
      getOrganisationMembership,
      api,
    ),
    takeLatest(
      OrganisationMembershipTypes.UPDATE_ORGANISATION_MEMBERSHIP_REQUEST,
      updateOrganisationMembership,
      api,
    ),
    takeLatest(
      OrganisationMembershipTypes.DESTROY_ORGANISATION_MEMBERSHIP_REQUEST,
      destroyOrganisationMembership,
      api,
    ),
    takeLatest(
      OrganisationMembershipTypes.CREATE_ORGANISATION_MEMBERSHIP_REQUEST,
      createOrganisationMembership,
      api,
    ),
    takeLatest(OrganisationMembershipTypes.RESEND_INVITATION_REQUEST, resendInvitation, api),

    takeLatest(UsersTypes.GET_USER_REQUEST, getUser, api),
    takeLatest(UsersTypes.GET_USER_FROM_EMAIL_REQUEST, getUserFromEmail, api),
    takeLatest(UsersTypes.CREATE_USER_REQUEST, createUser, api),
    takeLatest(UsersTypes.UPDATE_USER_REQUEST, updateUser, api),
    takeLatest(UsersTypes.GET_USERS_REQUEST, getUsers, api),
    takeLatest(UsersTypes.CREATE_HELPED_REQUEST, createHelped, api),
    takeLatest(UsersTypes.GET_USER_ORGANISATIONS_REQUEST, getUserOrganisations, api),

    takeLatest(EmailTypes.CHECK_EMAIL_TOKEN_REQUEST, checkEmailToken, api),
    takeLatest(EmailTypes.SEND_EMAIL_REQUEST, sendEmail, api),

    takeLatest(
      TransmissionsTypes.GET_ORGANISATION_TRANSMISSIONS_REQUEST,
      getOrganisationTransmissions,
      api,
    ),
    takeLatest(TransmissionsTypes.GET_LEAGUE_TRANSMISSIONS_REQUEST, getLeagueTransmissions, api),
    takeLatest(
      TransmissionsTypes.CREATE_ORGANISATION_TRANSMISSION_REQUEST,
      createOrganisationTransmission,
      api,
    ),
    takeLatest(
      TransmissionsTypes.CREATE_LEAGUE_TRANSMISSION_REQUEST,
      createLeagueTransmission,
      api,
    ),
    takeLatest(
      TransmissionsTypes.UPDATE_ORGANISATION_TRANSMISSION_REQUEST,
      updateOrganisationTransmission,
      api,
    ),
    takeLatest(
      TransmissionsTypes.UPDATE_LEAGUE_TRANSMISSION_REQUEST,
      updateLeagueTransmission,
      api,
    ),
    takeLatest(
      TransmissionsTypes.DESTROY_ORGANISATION_TRANSMISSION_REQUEST,
      destroyOrganisationTransmission,
      api,
    ),
    takeLatest(
      TransmissionsTypes.DESTROY_LEAGUE_TRANSMISSION_REQUEST,
      destroyLeagueTransmission,
      api,
    ),
    takeLatest(
      TransmissionsTypes.CREATE_ORGANISATION_TRANSMISSION_POST_REQUEST,
      createOrganisationTransmissionPost,
      api,
    ),
    takeLatest(
      TransmissionsTypes.CREATE_LEAGUE_TRANSMISSION_POST_REQUEST,
      createLeagueTransmissionPost,
      api,
    ),
    takeLatest(
      TransmissionsTypes.UPDATE_ORGANISATION_TRANSMISSION_POST_REQUEST,
      updateOrganisationTransmissionPost,
      api,
    ),
    takeLatest(
      TransmissionsTypes.UPDATE_LEAGUE_TRANSMISSION_POST_REQUEST,
      updateLeagueTransmissionPost,
      api,
    ),
    takeLatest(
      TransmissionsTypes.DESTROY_ORGANISATION_TRANSMISSION_POST_REQUEST,
      destroyOrganisationTransmissionPost,
      api,
    ),
    takeLatest(
      TransmissionsTypes.DESTROY_LEAGUE_TRANSMISSION_POST_REQUEST,
      destroyLeagueTransmissionPost,
      api,
    ),

    takeLatest(TasksTypes.CREATE_TASK_REQUEST, createTask, api),
    takeLatest(TasksTypes.UPDATE_TASK_REQUEST, updateTask, api),
    takeLatest(TasksTypes.SKIP_OCCURENCE_REQUEST, skipOccurence, api),
    takeLatest(TasksTypes.DETACH_TASK_OCCURRENCE_REQUEST, detachTaskOccurrence, api),
    takeLatest(TasksTypes.DESTROY_TASK_REQUEST, destroyTask, api),
    takeLatest(TasksTypes.REGISTER_HELPER_ON_TASK_REQUEST, registerHelperOnTask, api),
    takeLatest(TasksTypes.UNREGISTER_HELPER_ON_TASK_REQUEST, unregisterHelperOnTask, api),
    takeLatest(TasksTypes.VALIDATE_HELPER_ON_TASK_REQUEST, validateHelperOnTask, api),

    takeLatest(CareNotebooksTypes.GET_CARE_NOTEBOOK_POSTS_REQUEST, getCareNotebookPosts, api),
    takeLatest(CareNotebooksTypes.GET_CARE_NOTEBOOK_POST_REQUEST, getCareNotebookPost, api),
    takeLatest(CareNotebooksTypes.CREATE_CARE_NOTEBOOK_POST_REQUEST, createCareNotebookPost, api),
    takeLatest(CareNotebooksTypes.UPDATE_CARE_NOTEBOOK_POST_REQUEST, updateCareNotebookPost, api),
    takeLatest(CareNotebooksTypes.DELETE_CARE_NOTEBOOK_POST_REQUEST, deleteCareNotebookPost, api),
    takeLatest(
      CareNotebooksTypes.CREATE_CARE_NOTEBOOK_POST_COMMENT_REQUEST,
      createCareNotebookPostComment,
      api,
    ),
    takeLatest(
      CareNotebooksTypes.DELETE_CARE_NOTEBOOK_POST_COMMENT_REQUEST,
      deleteCareNotebookPostComment,
      api,
    ),
    // -
    // webNotificationSubscription
    // -
    takeLatest(
      WebNotificationSubscriptionsTypes.CREATE_WEB_NOTIFICATION_SUBSCRIPTION_REQUEST,
      createWebNotificationSubscription,
      api,
    ),
    takeLatest(
      WebNotificationSubscriptionsTypes.CHECK_WEB_NOTIFICATION_SUBSCRIPTION_REQUEST,
      checkWebNotificationSubscription,
      api,
    ),
    takeLatest(
      WebNotificationSubscriptionsTypes.DESTROY_WEB_NOTIFICATION_SUBSCRIPTION_REQUEST,
      destroyWebNotificationSubscription,
      api,
    ),

    //
    // deals
    //
    takeLatest(DealsTypes.GET_DEAL_REQUEST, getDeal, api),
    takeLatest(DealsTypes.GET_DEALS_REQUEST, getDeals, api),
    takeLatest(DealsTypes.CREATE_DEAL_REQUEST, createDeal, api),
    takeLatest(DealsTypes.CREATE_DEAL_POST_REQUEST, createDealPost, api),
    takeLatest(DealsTypes.DESTROY_DEAL_POST_REQUEST, destroyDealPost, api),
    takeLatest(DealsTypes.UPDATE_DEAL_REQUEST, updateDeal, api),
    takeLatest(DealsTypes.DESTROY_DEAL_REQUEST, destroyDeal, api),

    //
    // courses
    //
    takeLatest(CoursesTypes.GET_LEAGUE_COURSES_REQUEST, getLeagueCourses, api),
    takeLatest(CoursesTypes.GET_LEAGUE_COURSE_REQUEST, getLeagueCourse, api),
    takeLatest(CoursesTypes.CREATE_LEAGUE_COURSE_REQUEST, createLeagueCourse, api),
    takeLatest(CoursesTypes.UPDATE_LEAGUE_COURSE_REQUEST, updateLeagueCourse, api),
    takeLatest(CoursesTypes.DESTROY_LEAGUE_COURSE_REQUEST, destroyLeagueCourse, api),
    takeLatest(CoursesTypes.CREATE_LEAGUE_COURSE_UNIT_REQUEST, createLeagueCourseUnit, api),
    takeLatest(CoursesTypes.UPDATE_LEAGUE_COURSE_UNIT_REQUEST, updateLeagueCourseUnit, api),
    takeLatest(CoursesTypes.DESTROY_LEAGUE_COURSE_UNIT_REQUEST, destroyLeagueCourseUnit, api),
    takeLatest(CoursesTypes.GET_LEAGUE_COURSE_CHAPTER_REQUEST, getLeagueCourseChapter, api),
    takeLatest(CoursesTypes.CREATE_LEAGUE_COURSE_CHAPTER_REQUEST, createLeagueCourseChapter, api),
    takeLatest(CoursesTypes.UPDATE_LEAGUE_COURSE_CHAPTER_REQUEST, updateLeagueCourseChapter, api),
    takeLatest(CoursesTypes.DESTROY_LEAGUE_COURSE_CHAPTER_REQUEST, destroyLeagueCourseChapter, api),

    takeLatest(CoursesTypes.GET_HOLDING_COURSES_REQUEST, getHoldingCourses, api),
    takeLatest(CoursesTypes.GET_HOLDING_COURSE_REQUEST, getHoldingCourse, api),
    takeLatest(CoursesTypes.CREATE_HOLDING_COURSE_REQUEST, createHoldingCourse, api),
    takeLatest(CoursesTypes.UPDATE_HOLDING_COURSE_REQUEST, updateHoldingCourse, api),
    takeLatest(CoursesTypes.DESTROY_HOLDING_COURSE_REQUEST, destroyHoldingCourse, api),
    takeLatest(CoursesTypes.CREATE_HOLDING_COURSE_UNIT_REQUEST, createHoldingCourseUnit, api),
    takeLatest(CoursesTypes.UPDATE_HOLDING_COURSE_UNIT_REQUEST, updateHoldingCourseUnit, api),
    takeLatest(CoursesTypes.DESTROY_HOLDING_COURSE_UNIT_REQUEST, destroyHoldingCourseUnit, api),
    takeLatest(CoursesTypes.GET_HOLDING_COURSE_CHAPTER_REQUEST, getHoldingCourseChapter, api),
    takeLatest(CoursesTypes.CREATE_HOLDING_COURSE_CHAPTER_REQUEST, createHoldingCourseChapter, api),
    takeLatest(CoursesTypes.UPDATE_HOLDING_COURSE_CHAPTER_REQUEST, updateHoldingCourseChapter, api),
    takeLatest(
      CoursesTypes.DESTROY_HOLDING_COURSE_CHAPTER_REQUEST,
      destroyHoldingCourseChapter,
      api,
    ),

    takeLatest(CoursesTypes.CREATE_USER_CHAPTER_LINK_REQUEST, createUserChapterLink, api),
    takeLatest(CoursesTypes.GET_FAMILY_COURSES_REQUEST, getFamilyCourses, api),
    takeLatest(CoursesTypes.GET_COURSE_REQUEST, getCourse, api),
    takeLatest(CoursesTypes.GET_COURSE_CHAPTER_REQUEST, getCourseChapter, api),

    //
    // articles
    //
    takeLatest(ArticlesTypes.GET_HOLDING_ARTICLES_REQUEST, getHoldingArticles, api),
    takeLatest(ArticlesTypes.GET_HOLDING_ARTICLE_REQUEST, getHoldingArticle, api),
    takeLatest(ArticlesTypes.CREATE_HOLDING_ARTICLE_REQUEST, createHoldingArticle, api),
    takeLatest(ArticlesTypes.UPDATE_HOLDING_ARTICLE_REQUEST, updateHoldingArticle, api),
    takeLatest(ArticlesTypes.DESTROY_HOLDING_ARTICLE_REQUEST, destroyHoldingArticle, api),
    takeLatest(ArticlesTypes.GET_LEAGUE_ARTICLES_REQUEST, getLeagueArticles, api),
    takeLatest(ArticlesTypes.GET_LEAGUE_ARTICLE_REQUEST, getLeagueArticle, api),
    takeLatest(ArticlesTypes.CREATE_LEAGUE_ARTICLE_REQUEST, createLeagueArticle, api),
    takeLatest(ArticlesTypes.UPDATE_LEAGUE_ARTICLE_REQUEST, updateLeagueArticle, api),
    takeLatest(ArticlesTypes.DESTROY_LEAGUE_ARTICLE_REQUEST, destroyLeagueArticle, api),
    takeLatest(ArticlesTypes.GET_ARTICLE_REQUEST, getArticle, api),

    //
    // clubs
    //
    takeLatest(ClubsTypes.GET_ORGANISATION_CLUBS_REQUEST, getOrganisationClubs, api),
    takeLatest(ClubsTypes.GET_ORGANISATION_CLUB_REQUEST, getOrganisationClub, api),
    takeLatest(ClubsTypes.CREATE_CLUB_REQUEST, createClub, api),
    takeLatest(ClubsTypes.UPDATE_CLUB_REQUEST, updateClub, api),
    takeLatest(ClubsTypes.DESTROY_CLUB_REQUEST, destroyClub, api),
    takeLatest(ClubsTypes.CREATE_CLUB_MEMBERSHIPS_REQUEST, createClubMemberships, api),
    takeLatest(ClubsTypes.DESTROY_CLUB_MEMBERSHIP_REQUEST, destroyClubMembership, api),
    takeLatest(ClubsTypes.GET_CLUB_POSTS_REQUEST, getClubPosts, api),
    takeLatest(ClubsTypes.CREATE_CLUB_POST_REQUEST, createClubPost, api),

    //
    // algolia
    //
    takeLatest(AlgoliaTypes.GET_ALGOLIA_ORGANISATIONS_REQUEST, getAlgoliaOrganisations, api),
    takeLatest(AlgoliaTypes.GET_ALGOLIA_COURSES_REQUEST, getAlgoliaCourses, api),
  ]);
}
