import apisauce from 'apisauce';
import { isServerSide } from '../Config/ServerConfig';
import { locale } from '../I18n';
import hashToFormData, {
  appendAttachments,
  appendCover,
  appendToFormData,
} from './ApiDataSerializer';
import dispatchRequestResponseAction from './ApiTransforms';
import { INDEX_NAMES, searchClient } from './AlgoliaHelper';

const create = (baseURL = `${process.env.RAZZLE_BACKEND_URL}/`) => {
  if (isServerSide()) {
    console.log('baseURL', baseURL);
  }
  const apiClient = apisauce.create({
    baseURL,
    headers: {
      Accept: `application/json`,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
    timeout: 15000,
  });
  const apiClientLongTimeout = apisauce.create({
    baseURL,
    headers: {
      Accept: `application/json`,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
    timeout: 180000,
  });
  const apiClientNoTimeout = apisauce.create({
    baseURL,
    headers: {
      Accept: `application/json`,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  });

  dispatchRequestResponseAction(apiClient);
  dispatchRequestResponseAction(apiClientLongTimeout);
  dispatchRequestResponseAction(apiClientNoTimeout);

  //
  // Authorization
  //
  const setAuthToken = authToken => {
    if (authToken) {
      apiClient.setHeader('Authorization', `Bearer ${authToken}`);
      apiClientLongTimeout.setHeader('Authorization', `Bearer ${authToken}`);
      apiClientNoTimeout.setHeader('Authorization', `Bearer ${authToken}`);
      return true;
    }

    return false;
  };

  const removeAuthToken = () => {
    apiClient.deleteHeader('Authorization');
    apiClientLongTimeout.deleteHeader('Authorization');
    apiClientNoTimeout.deleteHeader('Authorization');
  };

  //
  // Authentication
  //
  const signIn = loginAttributes =>
    apiClient.post('v0/login', {
      helper: {
        ...loginAttributes,
      },
    });

  const resetPassword = emailOrPseudo => apiClient.delete('v0/passwords', { login: emailOrPseudo });
  const changePassword = (password, passwordConfirmation, token, oldPassword) =>
    apiClient.put('v0/passwords', {
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token: token,
      old_password: oldPassword,
    });
  const resendConfirmationEmail = (organisationSlug, email) =>
    apiClient.post(`v0/${organisationSlug}/confirmations`, { email });

  const signUpInvitation = (password, verificationToken) => {
    setAuthToken(verificationToken);
    return apiClient.post('v0/invitations/signup', {
      helper: {
        password,
      },
    });
  };

  const signUpConfirmation = verificationToken => {
    return apiClient.get(`v0/verify/${verificationToken}`);
  };

  const changePin = (pin, helperId) => {
    const formData = hashToFormData({ pin }, 'helper');
    return apiClient.put(`v0/helpers/${helperId}/update_pin`, formData);
  };

  const signUpOrganisation = (holdingSlug, organisationSlug, user) =>
    apiClient.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers/delayed_creation`,
      { helper: user },
    );

  //
  // subscriptions
  //
  const createHelperSubscription = (helperId, subscription) => {
    const formData = hashToFormData(subscription, 'subscription');
    return apiClient.post(`v0/helpers/${helperId}/subscriptions`, formData);
  };

  const updateHelperSubscription = (helperId, subscription) => {
    const formData = hashToFormData(subscription, 'subscription');
    formData.append('noDecorator', true);
    return apiClient.put(`v0/helpers/${helperId}/subscriptions/${subscription.id}`, formData);
  };

  const deleteHelperSubscription = (helperId, subscriptionId) => {
    return apiClient.delete(`v0/helpers/${helperId}/subscriptions/${subscriptionId}`);
  };

  //
  // notifications
  //
  const getHelperNotifications = helperId => {
    return apiClient.get(`v0/helpers/${helperId}/notifications`);
  };

  const updateHelperNotification = (helperId, notification) => {
    const formData = hashToFormData(notification, 'notification');
    formData.append('noDecorator', true);
    return apiClient.put(`v0/helpers/${helperId}/notifications/${notification.id}`, formData);
  };

  const deleteHelperNotification = (helperId, notificationId) => {
    return apiClient.delete(`v0/helpers/${helperId}/notifications/${notificationId}`);
  };

  //
  // Cities
  //
  const getCities = (term = '', allowedZipCodes = []) => {
    const urlParams = { term };
    if (allowedZipCodes.length > 0) {
      urlParams.zip_codes = allowedZipCodes;
    }
    return apiClient.get('v0/cities', urlParams);
  };
  //
  // Organisations
  //
  const getOrganisations = () => apiClient.get(`v0/organisations`);

  const getOrganisationsLeagueByCitySlug = (leagueId, citySlug) =>
    apiClient.get(`v0/leagues/${leagueId}/organisations_by_city_slug?city_slug=${citySlug}`);

  const getOrganisationsByLeague = leagueId =>
    apiClient.get(`v0/leagues/${leagueId}/organisations`);

  const addHoldingLeague = (leagueId, holdingSlug) => {
    return apiClient.post(`v0/leagues/${leagueId}/holdings/add`, {
      holding_slug: holdingSlug,
    });
  };
  const removeHoldingLeague = (leagueId, holdingSlug) =>
    apiClient.delete(`v0/leagues/${leagueId}/holdings/remove?holding_slug=${holdingSlug}`);

  //
  // Organisation
  //
  const getOrganisation = (holdingSlug, organisationSlug, fetchMode) => {
    let params = '';
    if (fetchMode === 'full') {
      params =
        '?include_services=true&include_deals=true&include_sampled_twigs=true&include_holding=true&include_sampled_helpers=true';
    } else if (fetchMode === 'services') {
      params = '?include_services=true&include_holding=true';
    } else {
      params = '?include_holding=true';
    }
    return apiClient.get(`v0/holdings/${holdingSlug}/organisations/${organisationSlug}${params}`);
  };

  const getOrganisationBySlug = organisationSlug => {
    return apiClient.get(`v0/organisations/by_slug/${organisationSlug}`);
  };

  const getOrganisationByIdentifier = (organisationIdentifier, method) =>
    apiClient.get(
      `v0/organisations/by_identifier/${organisationIdentifier}?method=${method || 'totem'}`,
    );

  const updateOrganisation = (holdingSlug, organisationSlug, updateAttributes, banner) => {
    let bodyParams = hashToFormData(updateAttributes, 'organisation');
    bodyParams = appendCover(bodyParams, 'organisation', 'banner', banner);

    return apiClientLongTimeout.patch(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}?include_holding=true`,
      bodyParams,
    );
  };

  const getOrganisationCircles = organisationId =>
    apiClient.get(`v0/organisations/${organisationId}/associations`);

  const getOrganisationService = (holdingSlug, organisationSlug, serviceSlug) =>
    apiClient.get(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/services/${serviceSlug}`,
    );

  const getOrganisationServices = (holdingSlug, organisationSlug) =>
    apiClient.get(`v0/holdings/${holdingSlug}/organisations/${organisationSlug}/services`);

  const addOrganisationService = (holdingSlug, organisationSlug, serviceAttributes, cover) => {
    const formData = hashToFormData(serviceAttributes, 'organisation_service');

    if (cover) {
      formData.append('organisation_service[file]', cover, cover.name);
    }
    return apiClient.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/services`,
      formData,
    );
  };

  const updateOrganisationService = (holdingSlug, organisationSlug, serviceAttributes, cover) => {
    const formData = hashToFormData(serviceAttributes, 'organisation_service');

    if (cover) {
      formData.append('organisation_service[file]', cover, cover.name);
    }
    return apiClient.put(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/services/${serviceAttributes.service_id}`,
      formData,
    );
  };

  const removeOrganisationService = (holdingSlug, organisationSlug, serviceId) =>
    apiClient.delete(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/services/${serviceId}`,
    );

  const createNonQualifiedLead = (holdingSlug, orgaSlug, lead) =>
    apiClient.post(`v0/holdings/${holdingSlug}/organisations/${orgaSlug}/leads/non_qualified`, {
      lead,
    });
  const createQualifiedLead = (holdingSlug, orgaSlug, lead) =>
    apiClient.post(`v0/holdings/${holdingSlug}/organisations/${orgaSlug}/leads/qualified`, {
      lead,
    });

  const createSharedLead = (holdingSlug, orgaSlug, lead) =>
    apiClient.post(`v0/holdings/${holdingSlug}/organisations/${orgaSlug}/leads/share`, { lead });

  const getLead = (holdingSlug, orgaSlug, leadId) =>
    apiClient.get(`v0/holdings/${holdingSlug}/organisations/${orgaSlug}/leads/${leadId}`);

  const createPost = (holdingSlug, organisationSlug, postableId, post, attachments, leagueId) => {
    let formData = hashToFormData(post, 'post');
    formData = appendAttachments(formData, 'post', 'attachments', attachments);

    if (leagueId) {
      return apiClientLongTimeout.post(
        `v0/leagues/${leagueId}/postable/${postableId}/posts`,
        formData,
      );
    }

    if (holdingSlug) {
      return apiClientLongTimeout.post(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/postable/${postableId}/posts`,
        formData,
      );
    }

    return apiClientLongTimeout.post(
      `v0/families/${organisationSlug}/postable/${postableId}/posts`,
      formData,
    );
  };

  const updatePost = (
    holdingSlug,
    organisationSlug,
    postableId,
    postAttributes,
    attachments,
    leagueId,
  ) => {
    let formData = hashToFormData(postAttributes, 'post');
    formData = appendAttachments(formData, 'post', 'attachments', attachments);

    if (leagueId) {
      return apiClientLongTimeout.put(
        `v0/leagues/${leagueId}/postable/${postableId}/posts/${postAttributes.id}`,
        formData,
      );
    }

    if (holdingSlug) {
      return apiClientLongTimeout.put(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/postable/${postableId}/posts/${postAttributes.id}`,
        formData,
      );
    }

    return apiClientLongTimeout.put(
      `v0/families/${organisationSlug}/postable/${postableId}/posts/${postAttributes.id}`,
      formData,
    );
  };

  const destroyPost = (holdingSlug, organisationSlug, postableId, postId, leagueId) => {
    if (leagueId) {
      return apiClient.delete(`v0/leagues/${leagueId}/postable/${postableId}/posts/${postId}`);
    }

    if (holdingSlug) {
      return apiClient.delete(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/postable/${postableId}/posts/${postId}`,
      );
    }

    return apiClient.delete(
      `v0/families/${organisationSlug}/postable/${postableId}/posts/${postId}`,
    );
  };

  //
  // Holding
  //

  const getHoldingLeads = (holdingSlug, filterByType, filterByArchived, page) => {
    const urlParams = {};

    if (filterByType !== null) {
      urlParams.type = filterByType;
    }
    if (filterByArchived !== null) {
      urlParams.archived = filterByArchived;
    }
    if (page !== null) {
      urlParams.page = page;
    }
    return apiClient.get(`v0/holdings/${holdingSlug}/leads`, urlParams);
  };

  const getOrganisationLeads = (
    holdingSlug,
    orgaSlug,
    filterByArchived,
    page,
    sortBy,
    sortDirection,
  ) => {
    const urlParams = {};
    if (filterByArchived !== null) {
      urlParams.archived = filterByArchived;
    }

    if (page !== null) {
      urlParams.page = page;
    }

    if (sortBy !== null) {
      urlParams.sort_by = sortBy;
    }

    if (sortDirection !== null) {
      urlParams.sort_direction = sortDirection;
    }

    return apiClient.get(`/v0/holdings/${holdingSlug}/organisations/${orgaSlug}/leads`, urlParams);
  };

  const getOrganisationAcceptedLeadsRange = (organisationId, range) => {
    const urlParams = {};
    if (range !== null) {
      urlParams.range = range;
    }
    return apiClient.get(`v0/organisations/${organisationId}/dashboards/acceptedleads`, urlParams);
  };

  const getOrganisationLeadsByType = (organisationId, range) => {
    const urlParams = {};
    if (range !== null) {
      urlParams.range = range;
    }
    /*    const api = apiClient.get(
      `v0/organisations/${organisationId}/dashboards/leadsbytype`,
      urlParams,
    );
    console.log(api); */
    return apiClient.get(`v0/organisations/${organisationId}/dashboards/leadsbytype`, urlParams);
  };

  const getOrganisationLeadsByDay = (organisationId, range) => {
    const urlParams = {};
    if (range !== null) {
      urlParams.range = range;
    }
    return apiClient.get(
      `v0/organisations/${organisationId}/dashboards/leadsbytypebyday`,
      urlParams,
    );
  };

  const getOrganisationLeadsByWeek = (organisationId, range) => {
    const urlParams = {};
    if (range !== null) {
      urlParams.range = range;
    }
    return apiClient.get(
      `v0/organisations/${organisationId}/dashboards/leadsbytypebyweek`,
      urlParams,
    );
  };

  const getOrganisationLeadsByMonth = (organisationId, range) => {
    const urlParams = {};
    if (range !== null) {
      urlParams.range = range;
    }
    return apiClient.get(
      `v0/organisations/${organisationId}/dashboards/leadsbytypebymonth`,
      urlParams,
    );
  };

  const getOrganisationTotalHelpers = (organisationId, role) => {
    const urlParams = {};
    if (role !== null) {
      urlParams.role = role;
    }
    return apiClient.get(`v0/organisations/${organisationId}/dashboards/totalhelpers`, urlParams);
  };

  const getOrganisationTopHelpers = (organisationId, role) => {
    const urlParams = {};
    if (role !== null) {
      urlParams.role = role;
    }
    /*    const api = apiClient.get(
      `v0/organisations/${organisationId}/dashboards/tophelpers`,
      urlParams,
    );
    console.log(api); */
    return apiClient.get(`v0/organisations/${organisationId}/dashboards/tophelpers`, urlParams);
  };

  const getOrganisationHelpers = (organisationId, role) => {
    const urlParams = {};
    if (role !== null) {
      urlParams.role = role;
    }
    return apiClient.get(
      `v0/organisations/${organisationId}/dashboards/helpersbygenderbyage`,
      urlParams,
    );
  };

  const getLeagueLeads = (leagueId, filterByArchived, page, organisationId) => {
    const urlParams = {};

    if (filterByArchived !== null) urlParams.archived = filterByArchived;
    if (page !== null) urlParams.page = page;
    if (organisationId !== null) urlParams.organisation_id = organisationId;

    return apiClient.get(`v0/leagues/${leagueId}/leads`, urlParams);
  };

  const getLeagueLead = (leagueId, leadId) => {
    return apiClient.get(`v0/leagues/${leagueId}/leads/${leadId}`);
  };

  const createLeagueLead = (leagueId, leadAttributes) => {
    const formData = hashToFormData(leadAttributes, 'lead');
    return apiClient.post(`v0/leagues/${leagueId}/leads`, formData);
  };

  const createLeagueLeadPost = (leagueId, leadId, postAttributes) => {
    const formData = hashToFormData(postAttributes, 'post');
    return apiClient.post(`v0/leagues/${leagueId}/leads/${leadId}/comment`, formData);
  };

  const updateLeagueLead = (leagueId, leadId, leadAttributes) => {
    const formData = hashToFormData(leadAttributes, 'lead');
    return apiClient.put(`v0/leagues/${leagueId}/leads/${leadId}`, formData);
  };

  const transferLeagueLead = (leagueId, leadId, organisationId) => {
    return apiClient.patch(
      `v0/leagues/${leagueId}/leads/${leadId}/transfer?organisation_id=${organisationId}`,
    );
  };

  const getFamilyLeads = (helperId, filterByArchived, page, filterTransport, year, month) => {
    const urlParams = {};

    if (filterByArchived !== null) {
      urlParams.archived = filterByArchived;
    }

    if (filterTransport !== null) {
      urlParams.transport = filterTransport;
    }

    if (year !== null) {
      urlParams.year = year;
    }

    if (month !== null) {
      urlParams.month = month;
    }
    if (page !== null) {
      urlParams.page = page;
    }
    return apiClientLongTimeout.get(`v0/helpers/${helperId}/leads`, urlParams);
  };

  const getFamilyLead = (helperId, leadId) =>
    apiClient.get(`v0/helpers/${helperId}/leads/${leadId}`);

  const updateHoldingLead = (holdingSlug, lead) =>
    apiClient.put(`v0/holdings/${holdingSlug}/leads/${lead.id}`, { lead });

  const updateOrganisationLead = (holdingSlug, orgaSlug, lead) =>
    apiClient.put(`v0/holdings/${holdingSlug}/organisations/${orgaSlug}/leads/${lead.id}`, {
      lead,
    });

  const updateFamilyLead = (helperId, lead) =>
    apiClient.put(`v0/helpers/${helperId}/leads/${lead.id}`, { lead });

  //
  // holdings
  //
  const getHolding = holdingSlug => apiClient.get(`v0/holdings/${holdingSlug}`);

  const getHoldings = () => apiClient.get(`v0/holdings`);

  const createHolding = (leagueId, holding, cover, charter, type, email) => {
    let bodyParams = hashToFormData(holding, '');
    bodyParams = appendAttachments(bodyParams, 'holding', 'charters', charter ? [charter] : null);
    bodyParams = appendCover(bodyParams, 'holding', 'cover', cover);
    bodyParams.append('type', type);
    if (email) bodyParams.append('user[email]', email);
    return apiClientLongTimeout.post(`v0/leagues/${leagueId}/holdings`, bodyParams);
  };

  const updateHolding = (leagueId, holdingSlug, holding, cover, charter, email) => {
    let bodyParams = hashToFormData(holding, 'holding');
    bodyParams = appendAttachments(bodyParams, 'holding', 'charters', charter ? [charter] : null);
    bodyParams = appendCover(bodyParams, 'holding', 'cover', cover);

    if (email) bodyParams.append('user[email]', email);

    return apiClientLongTimeout.patch(`v0/leagues/${leagueId}/holdings/${holdingSlug}`, bodyParams);
  };

  const addHoldingOrganisation = (leagueId, holdingSlug, organisationId) =>
    apiClient.patch(`v0/holdings/${holdingSlug}/organisations/add`, {
      organisation_id: organisationId,
    });

  const removeHoldingOrganisation = (holdingSlug, organisationId) =>
    apiClient.delete(`v0/holdings/${holdingSlug}/organisations/remove`, {
      organisation_id: organisationId,
    });

  const createOrganisationHolding = (holdingSlug, organisationAttributes, email) => {
    const formData = hashToFormData(organisationAttributes, 'organisation');
    if (email) formData.append('user[email]', email);
    return apiClientLongTimeout.post(`v0/holdings/${holdingSlug}/organisations`, formData);
  };

  const getHoldingService = (holdingSlug, serviceSlug) =>
    apiClient.get(`v0/holdings/${holdingSlug}/services/${serviceSlug}`);

  const getHoldingServices = holdingSlug => apiClient.get(`v0/holdings/${holdingSlug}/services`);

  const addHoldingService = (holdingSlug, serviceAttributes, image) => {
    let formData = hashToFormData(serviceAttributes, 'service');
    formData = appendCover(formData, 'service', 'file', image);

    return apiClientLongTimeout.post(`v0/holdings/${holdingSlug}/services`, formData);
  };

  const updateHoldingService = (holdingSlug, serviceAttributes, image) => {
    let formData = hashToFormData(serviceAttributes, 'service');
    formData = appendCover(formData, 'service', 'file', image);

    return apiClientLongTimeout.put(
      `v0/holdings/${holdingSlug}/services/${serviceAttributes.id}`,
      formData,
    );
  };

  const removeHoldingService = (holdingSlug, serviceId) =>
    apiClient.delete(`v0/holdings/${holdingSlug}/services/${serviceId}`);

  const getLeagueServices = leagueId => {
    return apiClientLongTimeout.get(`v0/leagues/${leagueId}/services`);
  };

  //
  // pathologies
  //
  const getAllPathologies = () => apiClient.get(`v0/pathologies`);

  //
  // expertises
  //
  const getAllExpertises = () => apiClient.get(`v0/expertises`);

  //
  // levels
  //
  const getLevels = () => apiClient.get(`v0/levels`);

  //
  // pathologies
  //
  const getAllEstablishmentTypes = () => apiClient.get(`v0/establishments`);

  //
  // rubrics
  //
  const addHoldingRubric = (holdingSlug, rubricAttributes) => {
    const formData = hashToFormData(rubricAttributes, 'rubric');
    return apiClient.post(`v0/holdings/${holdingSlug}/rubrics`, formData);
  };

  const updateHoldingRubric = (holdingSlug, rubricAttributes) => {
    const formData = hashToFormData(rubricAttributes, 'rubric');
    return apiClient.put(`v0/holdings/${holdingSlug}/rubrics/${rubricAttributes.id}`, formData);
  };

  const removeHoldingRubric = (holdingSlug, rubricId) =>
    apiClient.delete(`v0/holdings/${holdingSlug}/rubrics/${rubricId}`);

  //
  // services
  //
  const getServiceTypes = () => apiClient.get(`v0/service_categories`);

  //
  // leagues
  //
  const getLeague = (leagueIdOrSlug, includeHoldings, includeOrganisations, includeCommunities) => {
    let params = '';
    if (includeHoldings)
      params = includeOrganisations
        ? '?include_holdings=true&include_organisations=true'
        : '?include_holdings=true';
    else if (includeOrganisations) params = '?include_organisations=true';
    else if (includeCommunities) params = '?include_communities=true';

    return apiClient.get(`v0/leagues/${leagueIdOrSlug}${params}`);
  };

  const createLeagueCandidate = (leagueId, candidate, cv) => {
    const formData = hashToFormData(candidate, 'candidate');

    if (cv) {
      formData.append('candidate[file]', cv, cv.name);
    }
    return apiClient.post(`v0/leagues/${leagueId}/candidates`, formData);
  };

  const getLeagues = () => apiClient.get(`v0/leagues`);

  const getLeagueTasks = (leagueId, min, onlyCreatedHoldings) => {
    const params = onlyCreatedHoldings ? `&only_created_holdings=${onlyCreatedHoldings}` : '';
    return apiClientLongTimeout.get(`v0/leagues/${leagueId}/tasks?min_time=${min}${params}`);
  };

  const getLeagueSettings = leagueId => {
    return apiClientLongTimeout.get(`v0/leagues/${leagueId}/settings`);
  };

  const updateLeagueSettings = (leagueId, settings) => {
    return apiClientLongTimeout.patch(`v0/leagues/${leagueId}/settings`, { settings });
  };

  const getLeagueDeals = leagueId => apiClient.get(`v0/leagues/${leagueId}/deals`);

  const updateHoldingPremiumStatus = (leagueId, updateAttributes) => {
    const formData = hashToFormData(updateAttributes, 'holding_league');
    return apiClient.patch(`v0/leagues/${leagueId}/holdings/update_holding_league`, formData);
  };

  const getLeagueHelpers = (leagueId, page, organisationId) => {
    let params = '';
    if (organisationId)
      params = page
        ? `?organisation_id=${organisationId}&page=${page}`
        : `?organisation_id=${organisationId}`;
    else if (page) params = `?page=${page}`;

    return apiClientLongTimeout.get(`v0/leagues/${leagueId}/helpers${params}`);
  };

  const getLeagueHelper = (leagueId, helperId) =>
    apiClient.get(`v0/leagues/${leagueId}/helpers/${helperId}`);

  const createLeagueHelper = (leagueId, helperAttributes) => {
    const formData = hashToFormData(helperAttributes, 'helper');
    return apiClient.post(`v0/leagues/${leagueId}/helpers`, formData);
  };

  const updateLeagueHelper = (leagueId, helperId, helperLeagueAttributes) => {
    const formData = hashToFormData(helperLeagueAttributes, 'helper_league');
    return apiClient.patch(`v0/leagues/${leagueId}/helpers/${helperId}`, formData);
  };

  const transferLeagueHelper = (leagueId, helperId, organisationId) =>
    apiClient.patch(
      `v0/leagues/${leagueId}/helpers/${helperId}/transfer?organisation_id=${organisationId}`,
    );

  // -
  // OrganisationMembership
  // -
  const getOrganisationMembership = (holdingSlug, organisationSlug, role) => {
    const urlParams = {};
    if (role) {
      urlParams.by_role = role;
    }
    if (!holdingSlug) return apiClient.get(`v0/families/${organisationSlug}/helpers`, urlParams);

    return apiClientLongTimeout.get(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers`,
      urlParams,
    );
  };

  const updateOrganisationMembership = (
    holdingSlug,
    organisationSlug,
    membershipHelperId,
    updateAttributes,
  ) => {
    if (!holdingSlug) {
      return apiClient.patch(`v0/families/${organisationSlug}/helpers/${membershipHelperId}`, {
        helper_organisation: updateAttributes,
      });
    }

    return apiClient.patch(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers/${membershipHelperId}`,
      {
        helper_organisation: updateAttributes,
      },
    );
  };

  const destroyOrganisationMembership = (
    holdingSlug,
    organisationSlug,
    membershipHelperId,
    membershipId,
  ) => {
    if (!holdingSlug) {
      return apiClient.delete(
        `v0/families/${organisationSlug}/helpers/${membershipHelperId}`,
        membershipId
          ? {
              'helper_organisation[id]': membershipId,
            }
          : null,
      );
    }
    return apiClient.delete(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers/${membershipHelperId}`,
      membershipId ? { 'helper_organisation[id]': membershipId } : null,
    );
  };

  const createOrganisationMembership = (
    holdingSlug,
    organisationSlug,
    membershipAttributes,
    helperAttributes,
  ) => {
    let attributes = membershipAttributes;
    if (helperAttributes) attributes = { ...attributes, ...helperAttributes };
    const params = hashToFormData(attributes, 'helper_organisation');

    if (!holdingSlug) {
      return apiClient.post(`v0/families/${organisationSlug}/helpers`, params);
    }
    return apiClient.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers`,
      params,
    );
  };

  const resendInvitation = (holdingSlug, organisationSlug, helperId) => {
    if (!holdingSlug) {
      return apiClient.post(
        `v0/families/${organisationSlug}/helpers/${helperId}/resend_invitation`,
      );
    }
    return apiClient.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers/${helperId}/resend_invitation`,
    );
  };

  // -
  // User
  // -
  const getUser = userId => {
    return apiClient.get(`v0/helpers/${userId}`);
  };

  const getUserFromEmail = email => {
    const formattedEmail = email.replaceAll('+', '%2B');
    return apiClient.get(`v0/helpers?email=${formattedEmail}`);
  };

  const createUser = (user, origin) => {
    const dataForm = hashToFormData(user, 'helper');
    const formData = appendToFormData(dataForm, origin, 'origin');
    return apiClient.post(`v0/helpers`, formData);
  };

  const updateUser = (userId, updateAttributes, avatar, noDecorator) => {
    const formData = hashToFormData(updateAttributes, 'helper');

    if (avatar) {
      formData.append('helper[avatar]', avatar, avatar.name);
    }

    if (noDecorator) {
      formData.append('noDecorator', true);
    }

    return apiClient.patch(`v0/helpers/${userId}`, formData);
  };

  const getUsers = query => {
    const urlParams = { query };

    return apiClient.get('v0/helpers/search', urlParams);
  };

  const getUserOrganisations = userId => {
    return apiClient.get(`v0/helpers/${userId}/organisations`);
  };

  // -
  // Helped
  // -
  const createHelped = (attributes, avatar) => {
    const formData = hashToFormData(attributes, 'helped');

    if (avatar) {
      formData.append('helped[avatar]', avatar, avatar.name);
    }
    return apiClient.post(`v0/helpeds`, formData);
  };

  //
  // EMAIL
  //

  const checkEmailToken = token => apiClient.get(`v0/auth/email/${token}`);

  const sendEmail = email => apiClient.post(`v0/emails/send`, email);

  //
  // transmissions
  //

  const getOrganisationTransmissions = (holdingSlug, organisationSlug, helperOrgaId) => {
    return apiClient.get(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers_organisations/${helperOrgaId}/transmissions`,
    );
  };

  const getLeagueTransmissions = (leagueId, helperId) => {
    return apiClient.get(`/v0/leagues/${leagueId}/helpers/${helperId}/transmissions`);
  };

  const createOrganisationTransmission = (
    holdingSlug,
    organisationSlug,
    helperOrgaId,
    transmissionAttributes,
    attachments,
  ) => {
    let formData = hashToFormData(transmissionAttributes, 'transmission');
    formData = appendAttachments(formData, 'transmission', 'attachments', attachments);

    return apiClientLongTimeout.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers_organisations/${helperOrgaId}/transmissions`,
      formData,
    );
  };

  const createLeagueTransmission = (leagueId, helperId, transmissionAttributes) => {
    const formData = hashToFormData(transmissionAttributes, 'transmission');

    return apiClient.post(`/v0/leagues/${leagueId}/helpers/${helperId}/transmissions`, formData);
  };

  const updateOrganisationTransmission = (
    holdingSlug,
    organisationSlug,
    helperOrgaId,
    transmissionId,
    transmissionAttributes,
    attachments,
  ) => {
    let formData = hashToFormData(transmissionAttributes, 'transmission');
    formData = appendAttachments(formData, 'transmission', 'attachments', attachments);

    return apiClientLongTimeout.patch(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers_organisations/${helperOrgaId}/transmissions/${transmissionId}`,
      formData,
    );
  };

  const updateLeagueTransmission = (leagueId, helperId, transmissionId, transmissionAttributes) => {
    const formData = hashToFormData(transmissionAttributes, 'transmission');

    return apiClientLongTimeout.patch(
      `/v0/leagues/${leagueId}/helpers/${helperId}/transmissions/${transmissionId}`,
      formData,
    );
  };

  const destroyOrganisationTransmission = (
    holdingSlug,
    organisationSlug,
    helperOrgaId,
    transmissionId,
  ) => {
    return apiClient.delete(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers_organisations/${helperOrgaId}/transmissions/${transmissionId}`,
    );
  };

  const destroyLeagueTransmission = (leagueId, helperId, transmissionId) => {
    return apiClient.delete(
      `/v0/leagues/${leagueId}/helpers/${helperId}/transmissions/${transmissionId}`,
    );
  };

  //
  // tasks
  //

  const getTasks = min => apiClient.get(`v0/tasks?min_time=${min}`);

  const getTasksByLeague = leagueId => apiClient.get(`v0/leagues/${leagueId}/tasks`);

  // const getOrganisationTasks = (holdingSlug, organisationSlug) =>
  //   apiClient.get(`v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/featured`);

  const getTask = (holdingSlug, organisationSlug, familySlug, taskSlug, startDate) => {
    const urlParams = {};
    if (startDate) {
      urlParams.date = startDate;
    }

    if (holdingSlug && organisationSlug) {
      return apiClient.get(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/${taskSlug}`,
        urlParams,
      );
    }

    return apiClient.get(`v0/families/${familySlug}/tasks/${taskSlug}`, urlParams);
  };

  const createTask = (
    holdingSlug,
    organisationSlug,
    familySlug,
    taskAttributes,
    cover,
    duplicatedTaskSlug,
  ) => {
    const formData = hashToFormData(taskAttributes, 'task');
    const duplicateData = duplicatedTaskSlug ? `/${duplicatedTaskSlug}/duplicate` : '';

    if (cover) {
      formData.append('task[file]', cover, cover.name);
    }

    if (holdingSlug && organisationSlug) {
      return apiClientLongTimeout.post(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks${duplicateData}`,
        formData,
      );
    }

    return apiClientLongTimeout.post(`v0/families/${familySlug}/tasks${duplicateData}`, formData);
  };

  const updateTask = (holdingSlug, organisationSlug, familySlug, taskAttributes, cover) => {
    const formData = hashToFormData(taskAttributes, 'task');

    if (cover) {
      formData.append('task[file]', cover, cover.name);
    }
    if (holdingSlug && organisationSlug) {
      return apiClientLongTimeout.put(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/${taskAttributes.slug}`,
        formData,
      );
    }
    return apiClientLongTimeout.put(
      `v0/families/${familySlug}/tasks/${taskAttributes.slug}`,
      formData,
    );
  };

  const skipOccurence = (holdingSlug, organisationSlug, familySlug, taskSlug, eventDate) => {
    if (holdingSlug && organisationSlug) {
      return apiClient.put(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/${taskSlug}/events/skip/${eventDate}`,
      );
    }
    return apiClient.put(
      `v0/families/${organisationSlug}/tasks/${taskSlug}/events/skip/${eventDate}`,
    );
  };

  const detachTaskOccurrence = (
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    eventDate,
    taskAttributes,
    cover,
  ) => {
    const formData = hashToFormData(taskAttributes, 'task');
    if (cover) {
      formData.append('task[file]', cover, cover.name);
    }

    if (holdingSlug && organisationSlug) {
      return apiClient.post(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/${taskSlug}/detach/${eventDate}`,
        formData,
      );
    }
    return apiClient.post(
      `v0/families/${organisationSlug}/tasks/${taskSlug}/detach/${eventDate}`,
      formData,
    );
  };

  const destroyTask = (holdingSlug, organisationSlug, familySlug, taskSlug) => {
    if (holdingSlug && organisationSlug) {
      return apiClient.delete(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/${taskSlug}`,
      );
    }
    return apiClient.delete(`v0/families/${organisationSlug}/tasks/${taskSlug}`);
  };

  const getTwigs = (holdingSlug, organisationSlug, familySlug, endDate, startDate) => {
    if (holdingSlug && organisationSlug) {
      return apiClientLongTimeout.get(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/twigs?time_max=${endDate}&time_min=${startDate}`,
      );
    }

    return apiClient.get(
      `v0/families/${familySlug}/twigs?time_max=${endDate}&time_min=${startDate}`,
    );
  };

  const getTwig = (holdingSlug, organisationSlug, familySlug, twigId) => {
    if (holdingSlug && organisationSlug) {
      return apiClient.get(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/twigs/${twigId}`,
      );
    }

    return apiClient.get(`v0/families/${familySlug}/twigs/${twigId}`);
  };

  const registerHelperOnTask = (
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    helperId,
    eventDate,
    helpedId,
    helperAttributes,
  ) => {
    const bodyParams = Object.assign({
      helper_id: helperId,
      helped_id: helpedId,
      helper_params: helperAttributes,
    });

    if (holdingSlug && organisationSlug) {
      return apiClient.put(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/${taskSlug}/events/update_with_helper/${eventDate}`,
        bodyParams,
      );
    }
    return apiClient.put(
      `v0/families/${familySlug}/tasks/${taskSlug}/events/update_with_helper/${eventDate}`,
      bodyParams,
    );
  };

  const unregisterHelperOnTask = (
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    helperId,
    eventDate,
  ) => {
    const bodyParams = Object.assign({}, { helper_id: helperId });

    if (holdingSlug && organisationSlug) {
      return apiClient.put(
        `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/${taskSlug}/events/remove_helper/${eventDate}`,
        bodyParams,
      );
    }
    return apiClient.put(
      `v0/families/${familySlug}/tasks/${taskSlug}/events/remove_helper/${eventDate}`,
      bodyParams,
    );
  };

  const validateHelperOnTask = (holdingSlug, organisationSlug, taskSlug, helperId, eventDate) => {
    const bodyParams = Object.assign({}, { helper_id: helperId });

    return apiClient.put(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/tasks/${taskSlug}/events/validate_helper/${eventDate}`,
      bodyParams,
    );
  };

  //
  // topics
  //

  const getCareNotebookPosts = (familySlug, page) => {
    const urlParams = {};
    if (page !== null) {
      urlParams.page = page;
    }

    return apiClient.get(`v0/families/${familySlug}/care_notebooks`, urlParams);
  };

  const getCareNotebookPost = (familySlug, postId) => {
    return apiClient.get(`v0/families/${familySlug}/care_notebooks/${postId}`);
  };

  //
  // webNotificationSubscription
  //
  const createWebNotificationSubscription = (
    holdingSlug,
    organisationSlug,
    userId,
    subscription,
  ) => {
    if (!holdingSlug) {
      return apiClient.patch(
        `v0/families/${organisationSlug}/helpers/${userId}/create_subscription`,
        subscription,
      );
    }
    return apiClient.patch(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers/${userId}/create_subscription`,
      subscription,
    );
  };
  const checkWebNotificationSubscription = (holdingSlug, organisationSlug, userId, deviceId) => {
    if (!holdingSlug) {
      return apiClient.get(
        `v0/families/${organisationSlug}/helpers/${userId}/show_subscription?device_id=${deviceId}`,
      );
    }
    return apiClient.get(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers/${userId}/show_subscription?device_id=${deviceId}`,
    );
  };
  const destroyWebNotificationSubscription = (holdingSlug, organisationSlug, userId, deviceId) => {
    if (!holdingSlug) {
      return apiClient.delete(
        `v0/families/${organisationSlug}/helpers/${userId}/destroy_subscription?device_id=${deviceId}`,
      );
    }
    return apiClient.delete(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/helpers/${userId}/destroy_subscription?device_id=${deviceId}`,
    );
  };

  //
  // deals
  //
  const getDeal = (holdingSlug, organisationSlug, dealId) =>
    apiClient.get(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/deals/${dealId}?include_organisation=1`,
      // `v0/organisations/${organisationId}/deals/${dealId}?include_organisation=1&include_helper=1`,
    );

  const getDeals = (holdingSlug, organisationSlug, page) => {
    const urlParams = {};
    if (page !== null) {
      urlParams.page = page;
    }
    return apiClient.get(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/deals`,
      urlParams,
    );
  };

  const createDeal = (holdingSlug, organisationSlug, deal, attachments) => {
    let bodyParams = hashToFormData(deal, 'deal');
    bodyParams = appendAttachments(bodyParams, 'deal', 'attachments', attachments);

    return apiClientLongTimeout.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/deals`,
      bodyParams,
    );
  };

  const updateDeal = (holdingSlug, organisationSlug, dealAtributes, attachments) => {
    let bodyParams = hashToFormData(dealAtributes, 'deal');
    bodyParams = appendAttachments(bodyParams, 'deal', 'attachments', attachments);

    return apiClientLongTimeout.put(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/deals/${dealAtributes.id}`,
      bodyParams,
    );
  };

  const destroyDeal = (holdingSlug, organisationSlug, dealId) =>
    apiClient.delete(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/deals/${dealId}`,
    );

  //
  // COURSES
  //

  const getLeagueCourses = leagueId => {
    return apiClient.get(`v0/leagues/${leagueId}/courses`);
  };

  const getLeagueCourse = (leagueId, courseSlug) => {
    return apiClient.get(`v0/leagues/${leagueId}/courses/${courseSlug}`);
  };

  const createLeagueCourse = (leagueId, courseAttributes, cover, attachments) => {
    let formData = hashToFormData(courseAttributes, 'course');
    formData = appendCover(formData, 'course', 'cover', cover);
    formData = appendAttachments(formData, 'course', 'attachments', attachments);

    return apiClientNoTimeout.post(`v0/leagues/${leagueId}/courses`, formData);
  };

  const updateLeagueCourse = (leagueId, courseAttributes, cover, attachments) => {
    let formData = hashToFormData(courseAttributes, 'course');
    formData = appendCover(formData, 'course', 'cover', cover);
    formData = appendAttachments(formData, 'course', 'attachments', attachments);

    return apiClientNoTimeout.put(
      `v0/leagues/${leagueId}/courses/${courseAttributes.slug}`,
      formData,
    );
  };

  const destroyLeagueCourse = (leagueId, courseSlug) =>
    apiClient.delete(`v0/leagues/${leagueId}/courses/${courseSlug}`);

  const getHoldingCourses = holdingSlug => {
    return apiClient.get(`v0/holdings/${holdingSlug}/courses`);
  };

  const getHoldingCourse = (holdingSlug, courseSlug) => {
    return apiClient.get(`v0/holdings/${holdingSlug}/courses/${courseSlug}`);
  };

  const createHoldingCourse = (holdingSlug, courseAttributes, cover, attachments) => {
    let formData = hashToFormData(courseAttributes, 'course');
    formData = appendCover(formData, 'course', 'cover', cover);
    formData = appendAttachments(formData, 'course', 'attachments', attachments);
    return apiClientNoTimeout.post(`v0/holdings/${holdingSlug}/courses`, formData);
  };

  const updateHoldingCourse = (holdingSlug, courseAttributes, cover, attachments) => {
    let formData = hashToFormData(courseAttributes, 'course');
    formData = appendCover(formData, 'course', 'cover', cover);
    formData = appendAttachments(formData, 'course', 'attachments', attachments);

    return apiClientNoTimeout.put(
      `v0/holdings/${holdingSlug}/courses/${courseAttributes.slug}`,
      formData,
    );
  };

  const destroyHoldingCourse = (holdingSlug, courseSlug) =>
    apiClient.delete(`v0/holdings/${holdingSlug}/courses/${courseSlug}`);

  const getSitemapLeagueCourses = leagueId => {
    return apiClient.get(`v0/leagues/${leagueId}/courses/sitemap`);
  };

  const getFamilyCourses = helperId => {
    return apiClient.get(`v0/helpers/${helperId}/courses`);
  };

  const getCourse = courseSlug => {
    return apiClient.get(`v0/courses/${courseSlug}`);
  };

  //
  // COURSE UNITS
  //

  const createLeagueCourseUnit = (leagueId, courseSlug, unitAttributes) => {
    const formData = hashToFormData(unitAttributes, 'unit');
    return apiClient.post(`v0/leagues/${leagueId}/courses/${courseSlug}/units`, formData);
  };

  const updateLeagueCourseUnit = (leagueId, courseSlug, unitAttributes) => {
    const formData = hashToFormData(unitAttributes, 'unit');
    return apiClient.put(
      `v0/leagues/${leagueId}/courses/${courseSlug}/units/${unitAttributes.id}`,
      formData,
    );
  };

  const destroyLeagueCourseUnit = (leagueId, courseSlug, unitId) =>
    apiClient.delete(`v0/leagues/${leagueId}/courses/${courseSlug}/units/${unitId}`);

  const createHoldingCourseUnit = (holdingSlug, courseSlug, unitAttributes) => {
    const formData = hashToFormData(unitAttributes, 'unit');
    return apiClient.post(`v0/holdings/${holdingSlug}/courses/${courseSlug}/units`, formData);
  };

  const updateHoldingCourseUnit = (holdingSlug, courseSlug, unitAttributes) => {
    const formData = hashToFormData(unitAttributes, 'unit');
    return apiClient.put(
      `v0/holdings/${holdingSlug}/courses/${courseSlug}/units/${unitAttributes.id}`,
      formData,
    );
  };

  const destroyHoldingCourseUnit = (holdingSlug, courseSlug, unitId) =>
    apiClient.delete(`v0/holdings/${holdingSlug}/courses/${courseSlug}/units/${unitId}`);

  //
  // COURSE CHAPTERS
  //

  const getLeagueCourseChapter = (leagueId, courseSlug, chapterId) =>
    apiClient.get(`v0/leagues/${leagueId}/courses/${courseSlug}/chapters/${chapterId}`);

  const createLeagueCourseChapter = (leagueId, courseSlug, chapterAttributes, attachments) => {
    let formData = hashToFormData(chapterAttributes, 'chapter');
    formData = appendAttachments(formData, 'chapter', 'attachments', attachments);

    return apiClientLongTimeout.post(
      `v0/leagues/${leagueId}/courses/${courseSlug}/chapters`,
      formData,
    );
  };

  const updateLeagueCourseChapter = (leagueId, courseSlug, chapterAttributes, attachments) => {
    let formData = hashToFormData(chapterAttributes, 'chapter');
    console.log(attachments);
    formData = appendAttachments(formData, 'chapter', 'attachments', attachments);

    return apiClientNoTimeout.put(
      `v0/leagues/${leagueId}/courses/${courseSlug}/chapters/${chapterAttributes.id}`,
      formData,
    );
  };

  const destroyLeagueCourseChapter = (leagueId, courseSlug, chapterId) =>
    apiClient.delete(`v0/leagues/${leagueId}/courses/${courseSlug}/chapters/${chapterId}`);

  const getHoldingCourseChapter = (holdingSlug, courseSlug, chapterId) =>
    apiClient.get(`v0/holdings/${holdingSlug}/courses/${courseSlug}/chapters/${chapterId}`);

  const createHoldingCourseChapter = (holdingSlug, courseSlug, chapterAttributes, attachments) => {
    let formData = hashToFormData(chapterAttributes, 'chapter');
    formData = appendAttachments(formData, 'chapter', 'attachments', attachments);

    return apiClientLongTimeout.post(
      `v0/holdings/${holdingSlug}/courses/${courseSlug}/chapters`,
      formData,
    );
  };

  const updateHoldingCourseChapter = (holdingSlug, courseSlug, chapterAttributes, attachments) => {
    let formData = hashToFormData(chapterAttributes, 'chapter');
    formData = appendAttachments(formData, 'chapter', 'attachments', attachments);

    return apiClientNoTimeout.put(
      `v0/holdings/${holdingSlug}/courses/${courseSlug}/chapters/${chapterAttributes.id}`,
      formData,
    );
  };

  const destroyHoldingCourseChapter = (holdingSlug, courseSlug, chapterId) =>
    apiClient.delete(`v0/holdings/${holdingSlug}/courses/${courseSlug}/chapters/${chapterId}`);

  const createUserChapterLink = (userId, chapterId, attributes) => {
    return apiClient.post(
      `v0/helpers/${userId}/chapters/${chapterId}/create_link`,
      attributes
        ? {
            helper_chapter: attributes,
          }
        : null,
    );
  };

  const getCourseChapter = (courseSlug, chapterId) =>
    apiClient.get(`v0/courses/${courseSlug}/chapters/${chapterId}`);

  //
  // Articles
  //

  const getHoldingArticles = holdingSlug => {
    return apiClient.get(`v0/holdings/${holdingSlug}/articles`);
  };

  const getHoldingArticle = (holdingSlug, articleSlug) => {
    return apiClient.get(`v0/holdings/${holdingSlug}/articles/${articleSlug}`);
  };

  const createHoldingArticle = (holdingSlug, articleAttributes, cover, attachments) => {
    let formData = hashToFormData(articleAttributes, 'article');
    formData = appendCover(formData, 'article', 'cover', cover);
    formData = appendAttachments(formData, 'article', 'attachments', attachments);

    return apiClientLongTimeout.post(`v0/holdings/${holdingSlug}/articles`, formData);
  };

  const updateHoldingArticle = (holdingSlug, articleAttributes, cover, attachments) => {
    let formData = hashToFormData(articleAttributes, 'article');
    formData = appendCover(formData, 'article', 'cover', cover);
    formData = appendAttachments(formData, 'article', 'attachments', attachments);

    return apiClientLongTimeout.put(
      `v0/holdings/${holdingSlug}/articles/${articleAttributes.slug}`,
      formData,
    );
  };

  const destroyHoldingArticle = (holdingSlug, articleSlug) => {
    return apiClient.delete(`v0/holdings/${holdingSlug}/articles/${articleSlug}`);
  };

  const getLeagueArticles = leagueId => {
    return apiClient.get(`v0/leagues/${leagueId}/articles`);
  };

  const getSitemapLeagueArticles = leagueId => {
    return apiClient.get(`v0/leagues/${leagueId}/articles/sitemap`);
  };

  const getLeagueArticle = (leagueId, articleSlug) => {
    return apiClient.get(`v0/leagues/${leagueId}/articles/${articleSlug}`);
  };

  const createLeagueArticle = (leagueId, articleAttributes, cover, attachments) => {
    let formData = hashToFormData(articleAttributes, 'article');
    formData = appendCover(formData, 'article', 'cover', cover);
    formData = appendAttachments(formData, 'article', 'attachments', attachments);

    return apiClientLongTimeout.post(`v0/leagues/${leagueId}/articles`, formData);
  };

  const updateLeagueArticle = (leagueId, articleAttributes, cover, attachments) => {
    let formData = hashToFormData(articleAttributes, 'article');
    formData = appendCover(formData, 'article', 'cover', cover);
    formData = appendAttachments(formData, 'article', 'attachments', attachments);

    return apiClientLongTimeout.put(
      `v0/leagues/${leagueId}/articles/${articleAttributes.slug}`,
      formData,
    );
  };

  const destroyLeagueArticle = (leagueId, articleSlug) => {
    return apiClient.delete(`v0/leagues/${leagueId}/articles/${articleSlug}`);
  };

  const getArticle = articleSlug => {
    return apiClient.get(`v0/articles/${articleSlug}`);
  };
  //
  // CLUBS
  //

  const getOrganisationClubs = (holdingSlug, organisationSlug) => {
    return apiClient.get(`v0/holdings/${holdingSlug}/organisations/${organisationSlug}/clubs`);
  };

  const getOrganisationClub = (holdingSlug, organisationSlug, clubId) => {
    return apiClient.get(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/clubs/${clubId}`,
    );
  };

  const createClub = (holdingSlug, organisationSlug, clubAttributes) => {
    const formData = hashToFormData(clubAttributes, 'club');
    return apiClient.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/clubs`,
      formData,
    );
  };

  const updateClub = (holdingSlug, organisationSlug, clubAttributes) => {
    const formData = hashToFormData(clubAttributes, 'club');
    return apiClient.put(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/clubs/${clubAttributes.id}`,
      formData,
    );
  };

  const destroyClub = (holdingSlug, organisationSlug, clubId) => {
    return apiClient.delete(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/clubs/${clubId}`,
    );
  };

  const createClubMemberships = (holdingSlug, organisationSlug, clubId, membersIds) => {
    if (!membersIds || membersIds.length === 0) return null;
    const data = `{"helper_id": [${membersIds}]}`;
    return apiClient.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/clubs/${clubId}/helpers`,
      data,
    );
  };

  const destroyClubMembership = (holdingSlug, organisationSlug, clubId, membershipId) =>
    apiClient.delete(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/clubs/${clubId}/helpers/${membershipId}`,
    );

  const getClubPosts = (holdingSlug, organisationSlug, clubId, page, lastPostDate) => {
    const params = lastPostDate ? `?page=${page}&created_at=${lastPostDate}` : `?page=${page}`;
    return apiClient.get(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/clubs/${clubId}/posts${params}`,
    );
  };

  const createClubPost = (holdingSlug, organisationSlug, clubId, postAttributes) => {
    const formData = hashToFormData(postAttributes, 'post');
    return apiClient.post(
      `v0/holdings/${holdingSlug}/organisations/${organisationSlug}/postable/${clubId}/posts`,
      formData,
    );
  };

  //
  // ALGOLIA
  //

  const getAlgoliaOrganisations = queryParams => {
    const index = searchClient.initIndex(INDEX_NAMES.ORGANISATION);
    return index.search('', queryParams).then(({ hits, message }) => {
      return { hits, message };
    });
  };

  const getAlgoliaCourses = queryParams => {
    const index = searchClient.initIndex(INDEX_NAMES.COURSE);
    return index.search('', queryParams).then(({ hits, message }) => {
      return { hits, message };
    });
  };

  return {
    setAuthToken,
    removeAuthToken,

    signIn,
    resetPassword,
    changePassword,
    changePin,
    resendConfirmationEmail,
    signUpInvitation,
    signUpConfirmation,
    signUpOrganisation,
    createHelperSubscription,
    updateHelperSubscription,
    deleteHelperSubscription,
    getHelperNotifications,
    updateHelperNotification,
    deleteHelperNotification,

    getCities,
    getOrganisations,
    addHoldingLeague,
    removeHoldingLeague,

    createNonQualifiedLead,
    createQualifiedLead,
    createSharedLead,
    getLead,
    getFamilyLead,
    updateHoldingLead,
    updateOrganisationLead,
    updateFamilyLead,
    createPost,
    updatePost,
    destroyPost,
    getOrganisationsLeagueByCitySlug,
    getOrganisationsByLeague,
    getOrganisation,
    getOrganisationBySlug,
    getOrganisationByIdentifier,
    updateOrganisation,
    getOrganisationCircles,
    getOrganisationService,
    getOrganisationServices,
    addOrganisationService,
    updateOrganisationService,
    removeOrganisationService,
    getOrganisationLeadsByType,
    getOrganisationTopHelpers,
    getOrganisationHelpers,

    getHoldingLeads,
    getOrganisationLeads,
    getOrganisationAcceptedLeadsRange,
    getLeagueLeads,
    getLeagueLead,
    createLeagueLead,
    createLeagueLeadPost,
    transferLeagueLead,
    updateLeagueLead,
    getFamilyLeads,

    getOrganisationLeadsByDay,
    getOrganisationLeadsByWeek,
    getOrganisationLeadsByMonth,

    getHolding,
    getHoldings,
    updateHolding,
    createHolding,
    addHoldingOrganisation,
    removeHoldingOrganisation,
    createOrganisationHolding,
    getHoldingService,
    getHoldingServices,
    addHoldingService,
    updateHoldingService,
    removeHoldingService,
    getAllPathologies,
    getAllExpertises,
    getLevels,
    getAllEstablishmentTypes,
    addHoldingRubric,
    updateHoldingRubric,
    removeHoldingRubric,
    getLeagueServices,

    getServiceTypes,

    getLeague,
    getLeagues,
    createLeagueCandidate,
    getLeagueTasks,
    getLeagueSettings,
    updateLeagueSettings,
    getLeagueDeals,
    updateHoldingPremiumStatus,
    getLeagueHelpers,
    getLeagueHelper,
    createLeagueHelper,
    updateLeagueHelper,
    transferLeagueHelper,

    getOrganisationMembership,
    updateOrganisationMembership,
    destroyOrganisationMembership,
    createOrganisationMembership,
    resendInvitation,

    getUser,
    getUserFromEmail,
    createUser,
    updateUser,
    getOrganisationTotalHelpers,
    getUsers,
    createHelped,
    getUserOrganisations,

    checkEmailToken,
    sendEmail,

    getOrganisationTransmissions,
    getLeagueTransmissions,
    createOrganisationTransmission,
    createLeagueTransmission,
    updateOrganisationTransmission,
    updateLeagueTransmission,
    destroyOrganisationTransmission,
    destroyLeagueTransmission,

    getTasks,
    getTasksByLeague,
    createTask,
    updateTask,
    skipOccurence,
    detachTaskOccurrence,
    destroyTask,
    getTwigs,
    getTwig,
    getTask,
    registerHelperOnTask,
    unregisterHelperOnTask,
    validateHelperOnTask,

    getCareNotebookPosts,
    getCareNotebookPost,

    createWebNotificationSubscription,
    checkWebNotificationSubscription,
    destroyWebNotificationSubscription,

    getDeal,
    getDeals,
    createDeal,
    updateDeal,
    destroyDeal,

    getLeagueCourses,
    getLeagueCourse,
    createLeagueCourse,
    updateLeagueCourse,
    destroyLeagueCourse,
    getHoldingCourses,
    getHoldingCourse,
    createHoldingCourse,
    updateHoldingCourse,
    destroyHoldingCourse,
    getSitemapLeagueCourses,
    getFamilyCourses,
    getCourse,

    createLeagueCourseUnit,
    updateLeagueCourseUnit,
    destroyLeagueCourseUnit,
    createHoldingCourseUnit,
    updateHoldingCourseUnit,
    destroyHoldingCourseUnit,

    getLeagueCourseChapter,
    createLeagueCourseChapter,
    updateLeagueCourseChapter,
    destroyLeagueCourseChapter,
    getHoldingCourseChapter,
    createHoldingCourseChapter,
    updateHoldingCourseChapter,
    destroyHoldingCourseChapter,
    createUserChapterLink,
    getCourseChapter,

    getHoldingArticles,
    getHoldingArticle,
    createHoldingArticle,
    updateHoldingArticle,
    destroyHoldingArticle,
    getLeagueArticles,
    getSitemapLeagueArticles,
    getLeagueArticle,
    createLeagueArticle,
    updateLeagueArticle,
    destroyLeagueArticle,
    getArticle,

    getOrganisationClubs,
    getOrganisationClub,
    createClub,
    updateClub,
    destroyClub,
    createClubMemberships,
    destroyClubMembership,
    getClubPosts,
    createClubPost,

    getAlgoliaOrganisations,
    getAlgoliaCourses,
  };
};

export default create();
