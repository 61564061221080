import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import ToasterActions from '../../Redux/ToasterRedux';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
}));

export default function Toaster() {
  const classes = useStyles();
  const { t } = useTranslation();
  const open = useSelector(state => state.toaster.open);
  const message = useSelector(state => state.toaster.message);
  const timeout = useSelector(state => state.toaster.timeout);
  const level = useSelector(state => state.toaster.level);
  const currentUser = useSelector(state => state.auth.currentUser);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(ToasterActions.hideToaster());
  };

  let messageToDisplay = message;

  // If an array is received, like when the API return errors,
  // we display it as a string with HTML breaklines
  if (message instanceof Array) {
    messageToDisplay = message.join('<br />');
  }

  // If an object with a key `id` is received, like when we want to translate
  // a message, we fetch the corresponding translation to be displayed
  if (message && message.id) {
    messageToDisplay = t(message.id, { userHome: `/families/${currentUser?.family_slug}#edit=1` });
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={timeout}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[level]}
        aria-describedby="client-snackbar"
        message={
          // eslint-disable-next-line react/no-danger
          <span id="client-snackbar" dangerouslySetInnerHTML={{ __html: messageToDisplay }} />
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
