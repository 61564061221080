import { call, put } from 'redux-saga/effects';
import LeaguesActions from '../Redux/LeaguesRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import ToasterActions from '../Redux/ToasterRedux';

export function* createLeagueCandidate(api, { leagueId, candidate, cv }) {
  const response = yield call(api.createLeagueCandidate, leagueId, candidate, cv);

  if (response.ok) {
    yield put(LeaguesActions.createCandidateLeagueSuccess(response.data.candidate));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.createCandidateLeagueFailure(response.data));
  }
}

export function* getLeague(
  api,
  { leagueId, includeHoldings, includeOrganisations, includeCommunities },
) {
  const response = yield call(
    api.getLeague,
    leagueId,
    includeHoldings,
    includeOrganisations,
    includeCommunities,
  );

  if (response.ok) {
    yield put(LeaguesActions.getLeagueSuccess(response.data.league));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.getLeagueFailure(response.data));
  }
}

export function* getLeagues(api) {
  const response = yield call(api.getLeagues);

  if (response.ok) {
    yield put(LeaguesActions.getLeaguesSuccess(response.data.leagues));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.getLeaguesFailure(response.data));
  }
}

export function* getLeagueSettings(api, { leagueId }) {
  const response = yield call(api.getLeagueSettings, leagueId);

  if (response.ok) {
    yield put(LeaguesActions.getLeagueSettingsSuccess(response.data.settings));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.getLeagueSettingsFailure(response.data));
  }
}

export function* updateLeagueSettings(api, { leagueId, settings }) {
  const response = yield call(api.updateLeagueSettings, leagueId, settings);

  if (response.ok) {
    yield put(LeaguesActions.updateLeagueSettingsSuccess());
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.updateLeagueSettingsFailure(response.data));
  }
}

export function* getLeagueTasks(api, { leagueId, min, onlyCreatedHoldings }) {
  let response;
  if (!leagueId) {
    response = yield call(api.getTasks, min);
  } else {
    response = yield call(api.getLeagueTasks, leagueId, min, onlyCreatedHoldings);
  }

  if (response.ok) {
    yield put(LeaguesActions.getLeagueTasksSuccess(response.data.tasks));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.getLeagueTasksFailure(response.data));
  }
}

export function* updateHoldingPremiumStatus(api, { leagueId, updateAttributes }) {
  const response = yield call(api.updateHoldingPremiumStatus, leagueId, updateAttributes);

  if (response.ok) {
    yield put(LeaguesActions.updateHoldingPremiumStatusSuccess(response.data.holding));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.updateHoldingPremiumStatusFailure(response.data));
  }
}

export function* getLeagueHelpers(api, { leagueId, page, organisationId }) {
  const response = yield call(api.getLeagueHelpers, leagueId, page, organisationId);

  if (response.ok) {
    yield put(LeaguesActions.getLeagueHelpersSuccess(response.data.helpers));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.getLeagueHelpersFailure(response.data));
  }
}

export function* getLeagueHelper(api, { leagueId, helperId }) {
  const response = yield call(api.getLeagueHelper, leagueId, helperId);

  if (response.ok) {
    yield put(LeaguesActions.getLeagueHelperSuccess(response.data.helper));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.getLeagueHelperFailure({ ...response.data, status: response.status }));
  }
}

export function* createLeagueHelper(api, { leagueId, helperAttributes }) {
  const response = yield call(api.createLeagueHelper, leagueId, helperAttributes);

  if (response.ok) {
    yield put(LeaguesActions.createLeagueHelperSuccess(response.data.helper));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.createLeagueHelperFailure(response.data));
  }
}

export function* updateLeagueHelper(api, { leagueId, helperId, helperLeagueAttributes }) {
  const response = yield call(api.updateLeagueHelper, leagueId, helperId, helperLeagueAttributes);

  if (response.ok) {
    yield put(LeaguesActions.updateLeagueHelperSuccess(response.data.helper));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.updateLeagueHelperFailure(response.data));
  }
}

export function* transferLeagueHelper(api, { leagueId, helperId, organisationId }) {
  const response = yield call(api.transferLeagueHelper, leagueId, helperId, organisationId);

  if (response.ok) {
    yield put(LeaguesActions.transferLeagueHelperSuccess(response.data.helper));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.transferLeagueHelperFailure(response.data));
  }
}

export function* updateLeagueHelperInfos(api, { helperId, helperAttributes }) {
  const response = yield call(api.updateUser, helperId, helperAttributes);

  if (response.ok) {
    yield put(LeaguesActions.updateLeagueHelperInfosSuccess(response.data.helper));
  } else {
    yield toastResponseError(response);
    yield put(LeaguesActions.updateLeagueHelperInfosFailure(response.data));
  }
}
